import { useUI } from '@components/ui/context';
import CompareAtPrice from 'components/Product/CompareAtPrice/index';
import Price from 'components/Product/Price';
import { StorefrontContext } from 'providers/Storefront';
import { useContext, useEffect, useState } from 'react';
import { pluck } from 'utils';
import { EventContext } from 'providers/EventProvider';
import ProductAvailability from '../ProductAvailability';
import SaveToList from '../SaveToList';
import Badges from './Badges';
import Image from '../ProductCard/Image';
import Link from 'next/link';

type ShopifyProductCardProps = {
  product: any; // todo: type check
  hasMinHeightTitle?: '48px' | '24px';
};

const ShopifyProductCard = ({ product, hasMinHeightTitle }: ShopifyProductCardProps) => {
  if (!product) return null;
  const { closeSearchPanel } = useUI();

  const { gtm } = useContext(EventContext);

  const { handle, title, images, variants, tags, metafields } = product;

  const mappedVariants = variants?.edges.map((edge) => {
    const { sku, priceV2, compareAtPriceV2 } = edge.node;

    return {
      sku: sku,
      price: priceV2.amount,
      compareAtPrice: compareAtPriceV2?.amount,
    };
  });

  let defaultVariant = mappedVariants[0];
  const { price, compareAtPrice, sku } = defaultVariant;

  const filterMultiBuyOfferTag = tags?.filter((tag) => {
    return tag.startsWith('multibuy_offer');
  });

  const multibuyOffer = filterMultiBuyOfferTag[0]?.split(':')[1];
  let hasAvailabilityTag = tags.filter((tag) => {
    return tag.startsWith('availability');
  })[0];
  let availabilityTag = '';
  if (hasAvailabilityTag) {
    availabilityTag = hasAvailabilityTag?.toLowerCase()?.replace('availability:', '');
  }

  const { deliveryStore } = useContext(StorefrontContext);
  const [availableInStore, setAvailableInStore] = useState(false);
  const [storeLocationName, setStoreLocationName] = useState('');

  const availableStoreIds = metafields?.availablity?.store_location_ids || [];
  const titleMinHeight = hasMinHeightTitle ? '48px' : '24px';

  useEffect(() => {
    const storeLocationId = deliveryStore?.external_id;
    setStoreLocationName(deliveryStore?.name);

    if (availableStoreIds.includes(storeLocationId)) {
      setAvailableInStore(true);
    } else {
      setAvailableInStore(false);
    }
  }, [product]);

  const handleClickTracking = () => {
    gtm.tagClickProductCard({ label: `${sku}: ${title}` });
    closeSearchPanel();
  };

  return (
    <div className={`product-card`}>
      <Link href={`/p/${handle}`} onClick={handleClickTracking} prefetch={false}>
        <Image src={images.edges?.[0]?.node.transformedSrc} />
        <Badges product={product} multibuyOffer={multibuyOffer} />

        <div className="product-card-info">
          {availabilityTag && <ProductAvailability tag={availabilityTag} />}
          {availableInStore && (
            <div className="flex items-center text-sm mb-4 leading-4">
              <Image className="w-10" src="/icons/available-icon-green.svg" />
              <span>{`Available at ${storeLocationName}`}</span>
            </div>
          )}
          <div className="product-card-title">{title}</div>
          <div
            className="product-card-price-container"
            style={{ color: Number(compareAtPrice) > Number(price) ? '#df3c36' : '#474747' }}
          >
            <Price
              classNameMain="product-card-price-main"
              classNameSecondary="product-card-price-secondary"
              price={price}
              className=""
            />
            {Number(compareAtPrice) > Number(price) && (
              <CompareAtPrice compareAtPrice={compareAtPrice} />
            )}
          </div>
        </div>
      </Link>

      <div className="product-card-controls">
        <div className="product-card-controls-list">
          <SaveToList
            handle={handle}
            title={title}
            price={price}
            image={images.edges?.[0]?.node.transformedSrc || null}
            sku={sku || null}
          />
        </div>
      </div>
      <style jsx>
        {`
          .product-card-title {
            min-height: ${titleMinHeight};
          }
        `}
      </style>
    </div>
  );
};

export default ShopifyProductCard;
