import getClient from "../../shopify/shopify-gql-client-setting/ShopifyGQL";
import { collectionByHandle } from "../index";
import { captureException } from "@sentry/nextjs";
import { ShopifyClientContext } from "../client";
import { ProductConnection } from "shopify-storefront-api-typings";
import DefaultCollectionFilter from "@lib/shopify/filters/default-collection-filter";

type Metafields = {
  key: string;
  namespace: string;
  value: string;
}

type CollectionProps = {
  id: string;
  handle: string;
  title: string;
  description: string;
  descriptionHtml: string;
  metafields: Metafields[];
  products: ProductConnection
}

const parseShopifyCollection = (collection: CollectionProps) => {
  if (!collection) return null;
  const { id, handle, title, description, descriptionHtml, metafields, products } =
    collection;

  return {
    id,
    title,
    handle,
    description,
    descriptionHtml,
    metafields: metafields.filter((meta) => meta !== null),
    products,
  };
};

const client = (context: ShopifyClientContext) => {
  return getClient(
    context.shopifyDomain,
    context.storefrontAccessToken
  );
};

export async function getCollection(
  context: ShopifyClientContext,
  handle: string,
  first = 36,
  lastCursor = null,
  extraVariables?: any[]
) {
  const shopify = client(context);
  const variables = {
    handle,
    filters: DefaultCollectionFilter,
    first: first,
    after: lastCursor,
    ...extraVariables,
  };

  try {
    const response = await shopify(
      collectionByHandle,
      variables
    );
    return parseShopifyCollection(response.collection);
  } catch (error) {
    console.log('error', error);
    captureException(error);
    return null;
  }
}

export default getCollection;
