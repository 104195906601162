import { gql } from 'graphql-request';
import { COLLECTION_PRODUCT_FIELDS } from '@lib/shopify/queries/fragments/collection-product-fields';

const metafieldsIdentifier = `[
  {namespace: "etl", key: "sap_id"},
  {namespace: "etl", key: "sap_level"},
  {namespace: "etl", key: "sap_parent"},
  {namespace: "global", key: "description_tag"},
  {namespace: "global", key: "title_tag"},
  {namespace: "filtering", key: "toggle_gift_filters"}
  {namespace: "custom", key: "enable_promo_cards"}
  {namespace: "custom", key: "enable_prismic_content"}
  {namespace: "custom", key: "product_lifecycle_status"}
]`;

const collectionByHandle = gql`
    query CollectionQuery(
        $handle: String!
        $filters: [ProductFilter!]
        $first: Int
        $after: String
        $sortKey: ProductCollectionSortKeys
        $reverse: Boolean
    ) {
        collection(handle: $handle) {
            id
            handle
            title
            description
            descriptionHtml
            image {
                altText
                transformedSrc(maxWidth: 700)
            }
            metafields(identifiers: ${metafieldsIdentifier}){
              namespace
              key
              value
            }
            products(
                first: $first
                filters: $filters
                after: $after
                sortKey: $sortKey
                reverse: $reverse
            ) {
                edges {
                    cursor
                    node {
                        ...CollectionProductFields
                    }
                }
                filters {
                    id
                    label
                    type
                    values {
                        id
                        label
                        count
                        input
                    }
                }
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
        }
    }
    ${COLLECTION_PRODUCT_FIELDS}
`;

export default collectionByHandle;
